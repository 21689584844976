<script>
import axios from 'axios'
import infiniteScroll from 'vue-infinite-scroll'
// A single promise to queue requests to the next page
const queue = Promise.resolve()

export default {
  name: 'AggregationModal',
  props: {
    url: {
      required: true,
      type: String
    },
    title: {
      type: String
    }
  },
  directives: {
    infiniteScroll
  },
  data() {
    return {
      items: [],
      loaded: false,
      page: 1,
      limit: 25
    }
  },
  mounted() {
    // Get a items using an asynchrone query
    this.load()
      // Mark the component as loaded
      .then(() => this.loaded = true)
  },
  methods: {
    next() {
      this.page++
      return this.load()
    },
    load() {
      return queue.then(() => {
        // Get a items using an asynchrone query
        return axios.get(this.url, { params: this.params })
          // Cumulate items
          .then(res => this.items = this.items.concat(res.data))
      })
    }
  },
  computed: {
    params () {
      return {
        page: this.page,
        limit: this.limit
      }
    },
    reachTheEnd () {
      return this.items.length < (this.page * this.limit)
    }
  }
}
</script>

<template>
  <div class="aggregation-modal">
    <div v-infinite-scroll="next" infinite-scroll-disabled="reachTheEnd" class="aggregation-modal__wrapper border">
      <ul class="list-group list-group-flush" v-if="loaded">
        <li class="aggregation-modal__item list-group-item" v-for="(item, $index) in items" :bind="$index">
          <a :href="item.link">
            {{ item.name }}
          </a>
        </li>
      </ul>
      <ul class="list-group list-group-flush" v-else>
        <li class="list-group-item">
          <content-placeholder></content-placeholder>
        </li>
        <li class="list-group-item">
          <content-placeholder></content-placeholder>
        </li>
        <li class="list-group-item">
          <content-placeholder></content-placeholder>
        </li>
        <li class="list-group-item">
          <content-placeholder></content-placeholder>
        </li>
        <li class="list-group-item">
          <content-placeholder></content-placeholder>
        </li>
        <li class="list-group-item">
          <content-placeholder></content-placeholder>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '../utils/variables';

  .aggregation-modal {

    &__wrapper {
      max-height: 70vh;
      overflow: auto;

      .list-group-item {
        border: 0;

        &:nth-child(even) {
          background: $gray-100;
        }
      }
    }
  }
</style>
