import assign from 'lodash/assign'
import filter from 'lodash/filter'
import keys from 'lodash/keys'
import some from 'lodash/some'

export default {
  data () {
    return {
      query: '',
      queryParam: 'q[display_cont]'
    }
  },
  computed: {
    urlParams () {
      const searchParams = new URLSearchParams(window.location.search)
      const params = {}
      for(let entry of searchParams.entries())  {
        const key = entry[0] === 'q' ? this.queryParam : entry[0]
        params[key] = entry[1]
      }
      return params
    },
    currentParams () {
      const pageParams = window.location.pathname === '/search' ? this.urlParams : {}
      return assign(pageParams, { [this.queryParam]: this.query })
    },
    hasFilters () {
      return some(keys(this.urlParams), (key) => key.indexOf('f[') === 0)
    },
    activeFiltersCount () {
      return filter(keys(this.urlParams), (key) => key.indexOf('f[') === 0).length
    }
  },
  methods: {
    submitPath (params = this.currentParams) {
      const esc = encodeURIComponent
      const query = Object.keys(params).map(k => esc(k) + '=' + esc(params[k])).join('&')
      return `/search?${query}`

    }
  },
}
