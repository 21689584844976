<template>
  <imddb-header ref="header" no-headroom>
    <template slot="navbar">
      <ul class="navbar-nav mr-auto">
        <b-nav-dropdown @show="$root.$emit('bv::hide::popover')">
          <template slot="button-content">
            {{ title }}
          </template>
          <b-dropdown-item v-for="(item, $index) in dropdownItems"  :key="$index" :href="item.href" v-bind="{ active: !!item.active, target: item.target || '_self' }">
            {{ item.label }}
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-header>
            {{ $t('imddb-header.navbar.stories') }}
          </b-dropdown-header>
          <b-dropdown-item v-for="story in stories" :key="story.label" :href="story.href" target="_blank">
            {{ story.label }}
          </b-dropdown-item>
        </b-nav-dropdown>
      </ul>
    </template>
  </imddb-header>
</template>

<script>
  import { getLocales }  from "../utils/locales.js"
  export default {
    name: 'MainHeader',
    data () {
      return {
        dropdownItems: [
          { label: 'About this investigation', href: 'https://www.icij.org/investigations/implant-files/about-the-implant-files-investigation', target: '_blank' },
          { label: this.$config.get('app.name'), href: 'https://medicaldevices.icij.org' },
          { label: 'Tell us your story', href: 'https://www.icij.org/investigations/implant-files/do-events-in-the-implant-files-sound-familiar-share-your-story-with-the-world', target: '_blank' },
          { label: 'Resources for patients', href: 'https://www.icij.org/investigations/implant-files/resources-for-patients-and-frequently-asked-questions/', target: '_blank' },
          { label: 'Reporting partners', href: 'https://www.icij.org/implant-files-media-partners/', target: '_blank'  },
          { label: 'Video introduction', href: 'https://www.icij.org/investigations/implant-files/video-a-global-investigation-into-medical-device-harm/', target: '_blank'  }
        ],
        stories: [
          { label: "Patients harmed by medical devices", href: "https://www.icij.org/investigations/implant-files/medical-devices-harm-patients-worldwide-as-governments-fail-on-safety"},
          { label: "Europe, a device gateway to the world", href: "https://www.icij.org/investigations/implant-files/how-lobbying-blocked-european-safety-checks-for-dangerous-medical-implants"},
          { label: "History of the industry", href: "https://www.icij.org/investigations/implant-files/patient-hopes-rise-and-fall-as-an-industry-balances-progress-and-profit"},
          { label: "The Medtronic way", href: "https://www.icij.org/investigations/implant-files/medtech-giant-pushes-boundaries-as-casualties-mount-and-sales-soar"},
          { label: "Ruptured trust and breast implants", href: "https://www.icij.org/investigations/implant-files/breast-implant-injuries-kept-hidden-as-new-health-threats-surface/"},
          { label: "Heart device raises risk questions", href: "https://www.icij.org/investigations/implant-files/experts-warn-expanded-heart-valve-use-risks-patient-safety/"}
        ],
      }
    },
    computed: {
      title () {
        return this.$config.get('app.name')
      }
    },
    async mounted () {
      // Load locales from promise
      const locales = await getLocales()
      this.$refs.header.setLanguages(locales)
    }
  }
</script>
