<template>
  <b-modal ref="disclaimerModal" size="lg" :centered="true" hide-footer hide-header no-close-on-backdrop no-close-on-esc>
    <disclaimer-form @submit="hideDisclaimerModal()"></disclaimer-form>
  </b-modal>
</template>

<script>
  import { getCookie } from 'tiny-cookie'
  import DisclaimerForm from "../components/DisclaimerForm.vue"

  export default {
    name: 'DisclaimerModal',
    components: {
      DisclaimerForm
    },
    mounted () {
      if (this.$refs.disclaimerModal && !getCookie('imddb:disclaimer')) {
        this.$refs.disclaimerModal.show()
      }
    },
    methods: {
      hideDisclaimerModal() {
        if (this.$refs.disclaimerModal) {
          this.$refs.disclaimerModal.hide()
        }
      }
    }
  }
</script>
