<template>
  <div class="disclaimer-form p-4">
    <h3>
      {{ $t('disclaimer-form.heading') }}
    </h3>
    <p>
      {{ $t('disclaimer-form.description') }}
    </p>
    <form @submit.stop.prevent="submit">
      <div class="py-2 d-inline-block">
        <div class="custom-control custom-checkbox d-inline-block">
          <input type="checkbox" class="custom-control-input" id="termsCheck" v-model="termsCheck">
          <label class="custom-control-label font-weight-bold" for="termsCheck">
            {{ $t('disclaimer-form.optin') }}
          </label>
        </div>
      </div>
      <button class="btn btn-primary font-weight-bold text-uppercase ml-2" :disabled="!termsCheck">
        {{ $t('disclaimer-form.submit') }}
      </button>
    </form>
  </div>
</template>

<script>
  import { setCookie } from 'tiny-cookie'

  export default {
    name: 'DisclaimerForm',
    props: {
      cookieKey: {
        default: 'imddb:disclaimer',
        type: String
      }
    },
    data () {
      return {
        termsCheck: false,
      }
    },
    methods: {
      submit () {
        this.$emit('submit')
        // Save the submission
        setCookie(this.cookieKey, true,  { expires: '1Y' })
      }
    }
  }
</script>

<style lang="scss">
  @import '../utils/variables.scss';

  .disclaimer-form {
  }
</style>
