<script>
  export default {
    name: 'PaginatedList',
    props: {
      size: {
        type: Number,
        default: 10
      }
    },
    data () {
      return {
        page: 1,
        listItems: []
      }
    },
    mounted () {
      this.$el.classList.add('paginated-list')
      this.listItems = this.$el.querySelectorAll('li')
      this.reduce()
    },
    methods: {
      paginate () {
        this.listItems.forEach((item, index) => {
          item.classList.toggle('d-none', index >= this.limit)
        })
      },
      next () {
        this.page = Math.min(this.page + 1, this.maxPage)
        this.paginate()
      },
      reduce () {
        this.page = 1
        this.paginate()
      },
      hasMore () {
        return this.page < this.maxPage
      },
      canReduce () {
        return this.page > 1
      }
    },
    computed: {
      limit () {
        return this.page * this.size
      },
      left () {
        return ((this.page + 1) * this.size) - this.limit
      },
      maxPage () {
        return Math.ceil(this.listItems.length / this.size)
      }
    }
  }
</script>
