<script>
  export default {
    name: 'LangPicker',
    render(h) {
      const change = this.change
      return h("form", { on: { change } }, this.$slots.default)
    },
    methods: {
      change (event) {
        window.location = event.target.value
      }
    }
  }
</script>
