require.context('./assets/images', true)

import Turbolinks from 'turbolinks'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueI18n from 'vue-i18n'

import Murmur from "@icij/murmur"
import IframeResizer from '@icij/murmur/lib/utils/iframe-resizer'

import AdvancedSearchForm from "./components/AdvancedSearchForm.vue"
import AggregationCard from "./components/AggregationCard.vue"
import AggregationModal from "./components/AggregationModal.vue"
import DisclaimerModal from "./components/DisclaimerModal.vue"
import EventsMap from "./components/EventsMap.vue"
import LangPicker from "./components/LangPicker.vue"
import MainHeader from "./components/MainHeader.vue"
import PaginatedList from "./components/PaginatedList.vue"
import SearchForm from "./components/SearchForm.vue"
import SearchFormFilters from "./components/SearchFormFilters.vue"
import ScreendoorForm from "./components/ScreendoorForm.vue"

import turbolinks from "./mixins/turbolinks"
import locales from "./utils/locales.js"

import './application.scss'

Vue.use(VueI18n)
Vue.use(Murmur)
Vue.use(BootstrapVue)
Vue.mixin(turbolinks)

Murmur.config.set('app.home', '/')
Murmur.config.set('app.name', 'International Medical Devices Database')
Murmur.config.set('project.name', 'The Implant Files')
Murmur.config.set('signup-form.tracker', 'IMDDB')
Murmur.config.set('donate-form.tracker', '701f4000000mfuk')

for (let iso2 of locales) {
  Murmur.setLocaleMessage(iso2, require(`./locales/${iso2}.json`))
}

Murmur.setLocale(document.documentElement.lang || 'en')

Turbolinks.start()

if (IframeResizer.isEmbedded()) {
  IframeResizer.create()
}

document.addEventListener('turbolinks:load', () => {

  window.app = new Vue({
    el: '#app',
    i18n: Murmur.i18n,
    components: {
      AdvancedSearchForm,
      AggregationCard,
      AggregationModal,
      DisclaimerModal,
      EventsMap,
      LangPicker,
      MainHeader,
      PaginatedList,
      SearchForm,
      SearchFormFilters,
      ScreendoorForm
    }
  })
})
