<script>
import axios from 'axios'
import AggregationModal from './AggregationModal.vue'

export default {
  name: 'AggregationCard',
  components: {
    AggregationModal
  },
  props: {
    url: {
      required: true,
      type: String
    },
    limit: {
      type: Number,
      default: 5
    },
    category: {
      type: String
    },
    title: {
      type: String
    }
  },
  data() {
    return {
      items: [],
      loaded: false
    }
  },
  mounted() {
    // Get a items using an asynchrone query
    axios.get(this.url, { params: { limit: this.limit } })
      // Only pick a slice
      .then(res => this.items = res.data.slice(0, this.limit))
      // Mark the component as loaded
      .then(() => this.loaded = true)
  },
  computed: {
    hasMore () {
      return this.loaded && this.items.length == this.limit
    }
  },
  methods: {
    showMore () {
      this.$refs.aggregationModal.show()
    }
  }
}
</script>

<template>
  <div class="card aggregation-card">
    <div class="card-body pb-2">
      <h4 v-if="title" class="aggregation-card__heading mb-2">
        {{ title }}
        <span v-if="category" class="lead small text-muted text-nowrap">
          {{ category }}
        </span>
      </h4>
      <div v-if="!loaded" class="mt-1">
        <content-placeholder class="mb-2"></content-placeholder>
        <content-placeholder class="mb-2"></content-placeholder>
        <content-placeholder class="mb-2"></content-placeholder>
        <content-placeholder class="mb-2"></content-placeholder>
        <content-placeholder class="mb-2"></content-placeholder>
      </div>
      <ul class="list-unstyled mb-0">
        <li class="aggregation-card__item small" v-for="(item, $index) in items" :bind="$index">
          <a :href="item.link" class="text-truncate w-100 d-block pb-1">
            {{ item.name }}
          </a>
        </li>
      </ul>
      <b-modal hide-footer lazy ref="aggregationModal" :title="title">
        <aggregation-modal :url="url" />
      </b-modal>
    </div>
    <div class="card-footer py-1 px-2">
      <button class="btn btn-block btn-link btn-sms text-left font-weight-bold" @click="showMore">
        <i class="fas fa-external-link-alt mr-1 text-muted"></i>
        {{ $t('aggregation-card.more') }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .aggregation-card {
    &__heading {
      font-size: 1.2rem;
    }
  }
</style>
