<script>
import VueI18n from 'vue-i18n'
import get from 'lodash/get'
import throttle from 'lodash/throttle'
import search from '../mixins/search'
import axios from 'axios'
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  name: 'SearchForm',
  mixins: [ search ],
  props: {
    size: {
      type: String,
      default: 'sm'
    },
    initialValue:{
      type: String
    },
    noRounded:{
      type: Boolean
    },
    placeholder: {
      type: String
    },
    openNewWindow: {
      type: Boolean
    }
  },
  data () {
    return {
      query: this.initialValue,
      limit: 3,
      promise: Promise.resolve(),
      autosuggestActive: false,
      componentAttrClassAutosuggestResultsContainer: '.app',
      suggestions: [
        {
          data: []
        }
      ],
      inputProps: {
        onInputChange: throttle(this.onInputChange, 500),
        placeholder: this.placeholder || this.$t('search-form.placeholder'),
        initialValue: this.initialValue
      },
    }
  },
  components: {
    VueAutosuggest
  },
  computed: {
    isAutosuggestOpen () {
      return this.autosuggestActive && this.suggestions[0].data.length
    },
    formClasses () {
      const classes = [`search-form--${this.size}`]
      if (this.isAutosuggestOpen) {
        classes.push("search-form--open")
      }
      if (!this.noRounded) {
        classes.push("search-form--rounded")
      }
      return classes
    }
  },
  methods: {
    onFocus () {
      this.autosuggestActive = true
    },
    onBlur () {
      this.autosuggestActive = false
    },
    onSelected (suggestion = null) {
      const path = get(suggestion, 'item.path', this.submitPath())
      this.autosuggestActive = false
      // Embed set this to true
      if (this.openNewWindow) {
        window.open(path, '_blank')
      } else {
        Turbolinks.visit(path)
      }
    },
    getSuggestionValue (suggestion) {
      return this.query
    },
    onInputChange (query) {
      this.query = query
      if (query === '' || query === undefined || query.length < 2) {
        this.suggestions = [ { data: [] } ]
        return;
      }
      this.promise.then(() => {
        return axios.get('/search.json', { params: this.currentParams }).then(({ data }) => {
          this.suggestions = [ { data } ]
        })
      })
    }
  }
}
</script>

<template>
  <form class="search-form form-inline my-0" @submit.prevent="onSelected" :class="formClasses">
    <div class="input-group search-form__group">
      <vue-autosuggest :suggestions="suggestions" :on-selected="onSelected" :get-suggestion-value="getSuggestionValue" :limit="10" :input-props="inputProps" class="autosuggest" @focus="onFocus" @blur="onBlur">
        <template slot-scope="{ suggestion }">
          <div class="row">
            <div class="col text-truncate pr-0" v-html="$t('search-form.item', { label: suggestion.item.label_plural, query })"></div>
            <div class="col-auto">
              <span class="badge badge-dark rounded-pill mt-1" v-if="suggestion.item.count">
                {{ suggestion.item.count }}
              </span>
            </div>
          </div>
        </template>
      </vue-autosuggest>
      <div class="input-group-append">
        <button class="btn btn-outline-light search-form__submit my-sm-0" type="submit">
          <i class="fa fa-search"></i>
          <span class="sr-only">{{ $t('search-form.submit') }}</span>
        </button>
      </div>
    </div>
  </form>
</template>

<style lang="scss">
  @import '../utils/variables.scss';
  @import 'node_modules/bootstrap/scss/bootstrap';

  .search-form {
    $input-border-radius: .3em;
    width: 100%;

    &--open.search-form {

      &.search-form--rounded .form-control {
        border-radius: $input-border-radius 0 0 0;
      }

      .search-form__group {
        box-shadow: 0 10px 30px -5px rgba(#000, .15);
      }

      &.search-form--rounded .btn {
        border-radius: 0 $input-border-radius 0 0;
      }

      &.search-form--rounded  .autosuggest .autosuggest__results {
        border-radius: 0 0 $input-border-radius $input-border-radius;
      }
    }

    &--lg {

      .form-control {
        @extend .form-control-lg;
      }

      .btn {
        @extend .btn-lg;
      }
    }

    &__group {
      position: relative;
    }


    &.search-form--rounded input.form-control {
      border-radius: $input-border-radius 0 0 $input-border-radius;
    }

    input.form-control {
      width: 100%;
      border-right: 0;
      position: relative;
      z-index: 10;


      &:focus {
        outline: 0;
        box-shadow: none;
        border-color: $input-border-color;
      }
    }

    &.search-form--rounded .btn {
      border-radius: 0 $input-border-radius $input-border-radius 0;
    }

    .btn {
      color: $dark;
      position: relative;
      border-left: 0;
      background: $input-bg;
      border-color: $input-border-color;
    }

    &__group.input-group {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
      flex-wrap: nowrap;
    }

    .autosuggest {
      flex: 1 1 auto;

      &__results-container {
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 100%;
        z-index: $zindex-sticky;

        .autosuggest__results {
          @extend .dropdown-menu;
          box-shadow: 0 10px 30px -5px rgba(#000, .15);
          overflow: hidden;
          width: 100%;
          display: block;
          margin: 0;
          border-top: 0;

          ul {
            margin: 0;
            @extend .list-unstyled;
          }

          &_item {
            @extend .dropdown-item;
            font-size: .9rem;

            &-highlighted {
              color: $dropdown-link-active-color;
              text-decoration: none;
              @include gradient-bg($dropdown-link-active-bg);
            }
          }
        }
      }
    }
  }
</style>
