<script>
import $ from 'jquery'
import axios from 'axios'
import Datamaps from 'datamaps'
import reduce from 'lodash/reduce'
import throttle from 'lodash/throttle'
import { geoPath } from "d3-geo"
import { geoRobinson } from "d3-geo-projection"
import ResizeObserver from 'resize-observer-polyfill';

import IframeResizer from '@icij/murmur/lib/utils/iframe-resizer'
// Make it available globally (Datamaps is not really ESM-friendly)
window.Datamaps = window.Datamaps || Datamaps


export default {
  name: 'EventsMap',
  props: {
    noInstructions: {
      type: Boolean
    },
    openNewWindow: {
      type: Boolean
    },
    iframeUrl: {
      type: String
    }
  },
  data() {
    return {
      map: null,
      data: {}
    }
  },
  mounted() {
    axios.get('/events/countries.json', { params: { limit: 50 }})
      // Transform the data
      .then(this.transformData)
      // And build the map
      .then(this.build)
      // Watch for windows change
      .then(this.bindResizeObserver)
  },
  computed: {
    wrapper() {
      return document.querySelector('.events-map__wrapper > div')
    },
    embeddedWithPym () {
      return IframeResizer.isEmbedded()
    }
  },
  methods: {
    build (data) {
      this.map = new Datamaps({
        data,
        element: this.wrapper,
        responsive: true,
        // Use a custom projection
        setProjection: this.setProjection,
        // Bind click on the maps
        done: m => {
          m.svg.selectAll('.datamaps-subunit').on('click', this.onClick)
          // Embedded mode (with Pym) mean the height of the container must follow the map's height
          if (IframeResizer.isEmbedded()) {
            // Sent the height of the window after the map is rendered
            IframeResizer.create().then(pymChild => pymChild.sendHeight())
          }
        },
        // Define countries' colors
        fills: {
          'ACTIVE': '#A83324',
          defaultFill: '#ebf7ff'
        },
        // No borders
        geographyConfig: {
          popupOnHover: true,
          highlightOnHover: false,
          borderColor: (geography) => this.isActive(geography) ? '#9c2f21' : '#ebf7ff',
          popupTemplate: (geography, data) => {
            if (data) {
              return `<div class="hoverinfo">
                <strong class="text-uppercase">${data.name}</strong>
                <div class="text-muted">${this.$tc('events-map.records', data.events_count)}</div>
              </div>`
            } else {
              return `<div class="hoverinfo text-muted">
                <strong class="text-uppercase">${geography.properties.name}</strong>
                <div>${this.$t('events-map.no-records')}</div>
              </div>`
            }
          }
        }
      })
    },
    transformData (response) {
      return this.data = reduce(response.data, (res, country) => {
        res[country.alpha3] = { fillKey: 'ACTIVE', ...country }
        return res
      }, {})
    },
    setProjection (element, options) {
      const width = options.width || element.offsetWidth;
      const height = options.height || element.offsetHeight;
      const projection = geoRobinson()
        .scale((width + 1) / 2 / Math.PI)
        .translate([width / 2, height / 1.8])
      const path = geoPath().projection( projection );
      return { path, projection }
    },
    isActive(geographyOrId) {
      return !!this.data[geographyOrId.id || geographyOrId]
    },
    bindResizeObserver () {
      const ro = new ResizeObserver(throttle(this.resize, 300))
      ro.observe(this.wrapper);
    },
    resize () {
      delete this.map
      this.emptyWrapper()
      this.build(this.data)
    },
    emptyWrapper () {
      while (this.wrapper.firstChild) {
        this.wrapper.removeChild(this.wrapper.firstChild);
      }
    },
    onClick (geography) {
      if (this.isActive(geography)) {
        // Embed set this to true
        if (this.openNewWindow) {
          window.open(this.data[geography.id].link, '_blank')
        } else {
          Turbolinks.visit(this.data[geography.id].link)
        }
      }
    }
  }
}
</script>

<template>
  <div class="events-map" :class="{ 'events-map--embedded-with-pym': embeddedWithPym, 'events-map--not-embedded-with-pym': !embeddedWithPym }">
    <div class="events-map__instructions lead px-4 py-3" v-if="!noInstructions">
      <div class="events-map__instructions__body">
        <i class="far fa-hand-pointer mr-2"></i>
        {{ $t('events-map.introductions') }}
      </div>
      <button v-if="iframeUrl" class="btn btn-sm py-0 events-map__instructions__embed-btn" @click="$refs.embedForm.show()">
        <i class="fa fa-code"></i>
        <span class="sr-only">{{ $t('events-map.embed') }}</span>
      </button>
    </div>
    <div class="events-map__wrapper">
      <div></div>
    </div>
    <b-modal size="lg" hide-footer lazy ref="embedForm" title="Embed the map on your website" class="text-dark">
      <embed-form no-title :height="400" :url="iframeUrl"></embed-form>
    </b-modal>
  </div>
</template>

<style lang="scss">
  @import '../utils/variables';

  .events-map {
    width: 100%;
    height: 100%;
    background: $home-overview-bg;

    &__wrapper {
      min-height: 0;
      width: 100%;
      position: relative;

      & > div {

        .datamap {
          overflow: visible !important;

          .datamaps-subunits {
            filter: drop-shadow(0 -1rem 2rem darken($home-overview-bg, 10));
          }
        }

        .datamaps-hoverover .hoverinfo {
          box-shadow: $popover-box-shadow;
          border: none;
        }
      }
    }


    &__instructions {
      display: flex;
      flex-direction: row;
      align-items: center;

      position: absolute;
      bottom: 0;
      left: 0;

      z-index: 2;
      color: #fff;
      background: #000;
      width: 100%;
      box-shadow: $grid-gutter-width 0 0 black, -$grid-gutter-width 0 0 black;

      &__body {
        flex-grow: 1;
        min-width: 0;
      }

      &__embed-btn.btn {
        background: #fff;
        width: 2.2rem;
        height: 2.2rem;
        line-height: 2.2rem;
        border-radius: 50%;
        text-align: center;
      }
    }
  }
</style>
